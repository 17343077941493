<template>
  <div class="pa-2">
    <profile-card />
    <v-list dense v-if="isAdmin">
      <v-list-item-group :value="item" @change="routeChanged" color="primary">
        <v-list-item v-for="i in items" :key="i.code">
          <v-list-item-content>
            <v-list-item-title class="text-button">{{
              i.name
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-divider />
    <div>
      <v-row no-gutters align="center" justify="center" dense class="my-4">
        <v-combobox
          :value="vin"
          @change="saveVin"
          :items="vins"
          placeholder="VIN"
          dense
          filled
          rounded
          hide-details
          append-icon=""
        />
      </v-row>
      <v-row no-gutters align="center" justify="center" dense class="my-4">
        <v-combobox
          :value="authID"
          @change="saveAuthID"
          :items="authIDs"
          placeholder="Auth ID"
          dense
          filled
          rounded
          hide-details
          append-icon=""
        />
      </v-row>
      <v-row no-gutters align="center" justify="center" dense class="my-4">
        <v-combobox
          :value="version"
          @change="saveVersion"
          :items="versions"
          placeholder="Version"
          dense
          filled
          rounded
          hide-details
          append-icon=""
        />
      </v-row>
      <v-row no-gutters align="center" justify="center" dense class="my-4">
        <v-select
          placeholder="Langauge"
          dense
          filled
          rounded
          hide-details
          :value="language"
          :items="languages"
          @change="saveLanguage"
          item-text="name"
          item-value="code"
          return-object
          :disabled="languages.length < 2"
        />
      </v-row>
      <v-row no-gutters align="center" justify="center" dense class="my-4">
        <v-select
          placeholder="Environment"
          dense
          filled
          rounded
          hide-details
          :value="environment"
          :items="environments"
          @change="saveEnvironment"
          item-text="name"
          item-value="code"
          return-object
          :disabled="environments.length < 2"
        />
      </v-row>
      <!-- <v-row no-gutters align="center" justify="center" dense class="my-4">
        <v-select placeholder="Location" dense filled rounded hide-details />
      </v-row>
      <v-row align="center" justify="center" dense class="my-4">
        <v-select placeholder="Head-unit" dense filled rounded hide-details />
      </v-row> -->
      <v-row no-gutters align="center" justify="center" dense class="my-4">
          <v-switch inset :value="textSpeech" class="flex mx-2" @change="saveTextSpeech">
            <template v-slot:label>
              <div>Text to Speech</div>
            </template>
          </v-switch>
      </v-row>
    </div>
    <v-row no-gutters align="center" justify="center" dense>
      <v-btn
        class="rounded-10"
        color="secondary"
        block
        @click="logout"
      >
        logout
      </v-btn>
    </v-row>
    <div class="footer py-1">@ 2021 MBRDNA. All Rights Reserved</div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  components: {
    "profile-card": () => import("../components/Profile.vue")
  },
  computed: {
    ...mapState("setting", [
      "textSpeech",
      "languages",
      "environments",
      "language",
      "environment",
      "vin",
      "authID",
      "version",
      "vins",
      "authIDs",
      "versions"
    ]),
    ...mapGetters(["isAdmin"]),
    item() {
      return this.$route.path === "/" ? 0 : 1;
    }
  },
  data: () => ({
    items: [
      { code: "home", name: "Chat Bot" },
      { code: "admin", name: "Admin" }
    ]
  }),
  methods: {
    ...mapActions(["logout"]),
    ...mapActions("setting", [
      "saveEnvironment",
      "saveLanguage",
      "saveAuthID",
      "saveVin",
      "saveVersion",
      "saveTextSpeech"
    ]),
    routeChanged(selection) {
      if (selection === undefined) return;
      const path = this.items[selection];
      if (this.$route.name === path.code) return;
      this.$router.push({ name: path.code });
    }
  }
};
</script>

<style scoped>
.footer {
  position: fixed;
  bottom: 0;
  width: calc(100% - 16px);
  text-align: center;
}
.flex {
  flex: 1 1 auto !important;
}
</style>
